import React from "react";
import "./modal.css"; // We'll use a CSS file to style the modal (or you can inline the styles)


const Modal = ({ isOpen, onClose, children } ) => {
  if (!isOpen) {
    return null; // If the modal is not open, return null (no modal will be rendered)
  }

  return (
    <div className='modal-overlay' onClick={onClose}>
      <div
        className='modal-content'
        onClick={(e) => e.stopPropagation()}
        style={{ borderRadius: "39px" }}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
