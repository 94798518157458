
import { useCallback, useState, useEffect } from 'react';
import './App.css';
import gifImage from "./assets/logo.gif";
import Modal from './modal';

const companyNames = [
  "Trash Panda",
  "Bubble Gum Factory",
  "Fizzy Lemon",
  "Moss & Stone",
  "Chuckle Hut",
  "Wild Tusk",
  "Cactus Milk",
  "The Roost",
  "Neon Leaf",
  "Rusty Robot",
  "Velvet Jungle",
  "Starfruit Station",
  "Grit & Glory",
  "ZigZag City",
  "Lazy Rhino",
  "Shit box",
  "Medixor",
  "Healova",
  "Vitaro",
  "CuroWell",
  "ZenHealth",
  "MediCrest",
  "Vitalora",
  "NexaCare",
  "Curelite",
  "Pulseway",
  "MediSage",
  "VitaSphere",
  "OptiHealth",
  "CareFlex",
  "SerenoMed",
  "Curezio",
  "Vitalyze",
  "MediAura",
  "Healium",
  "PulsePoint",
  "CuraZen",
  "Healthora",
  "NexWell",
  "VitaNest",
  "HelixMed",
  "MediPath",
  "PulseLink",
  "HealSync",
  "Vitalbridge",
  "CareHive",
  "Curevia",
  "MediSpring",
  "OptiCure",
  "VitaGlow",
  "CuroSphere",
  "Wellify",
  "MediNest",
  "PulseTrack",
  "CuraLife",
  "NexaMed",
  "HealGrid",
  "MediTrek",
  "HealthSync",
  "VitalSync",
  "HealQuest",
  "Curewell",
  "MediBloom",
  "PulseCraft",
  "VitalSpring",
  "Movon",
  "Swiftly",
  "Glidee",
  "RideX",
  "Veloz",
  "Driftly",
  "TrackMob",
  "Movezy",
  "Rollio",
  "Zoomsy",
  "Flexmove",
  "Cruizeo",
  "Trekzy",
  "Motionix",
  "GoFleet",
  "Shiftly",
  "NexMove",
  "Transio",
  "Flowr",
  "Ziptrack",
  "LoopRider",
  "Speedly",
  "MoveUp",
  "Glideon",
  "Shiftzy",
  "Vroomeo",
  "Triply",
  "Driftzy",
  "RapidGo",
  "Roamio",
  "Urbano",
  "Cartiq",
  "Wheelzy",
  "MoveLynk",
  "Paxtra",
  "Hopzy",
  "Rollink",
  "NexaMob",
  "Flytway",
  "Cruizr",
  "Swiftro",
  "Tracksy",
  "Tourio",
  "LoopRide",
  "Drivza",
  "Tripster",
  "GoShift",
  "ZipGo",
  "Motivo",
  "Rydio",
  "VibeMove",
  "Mudbox",
  "Wild Oak",
  "Pinepatch",
  "Starlight Hub",
  "River Bend",
  "Glowstone",
  "Cloud Nest",
  "Rusty Barrel",
  "Blue Finch",
  "Salt Creek",
  "Mossy Hollow",
  "Driftwood Point",
  "Copper Kettle",
  "Green Grove",
  "Firefly Cove",
  "Stonewheel",
  "Willow Stand",
  "Echo Loft",
  "Rusted Spoon",
  "Ember Trail",
  "Cedar Hill",
  "Driftstone",
  "Golden Well",
  "Timber Haven",
  "Moonrise Lodge",
  "Crimson Root",
  "Creekside",
  "Fawn Hollow",
  "Boulder Grove",
  "Prairie Nest",
  "Hollow Log",
  "Sandstone Ridge",
  "Oakwell",
  "Blue Roost",
  "Driftmoor",
  "Meadowpine",
  "Whistling Tree",
  "Feather Brook",
  "Sunflower Loft",
  "Elm Creek",
  "Amber Hearth",
  "Woodside Hollow",
  "Riverstone",
  "Maple Den",
  "Cloudbar",
  "Meadowfield",
  "Rusted Gear",
  "Sandbar",
  "Pine Forge",
  "Fernwell",
  "Ashbrook",
  "Golden Crest",
  "Bramble Hollow",
  "Birch Nest",
  "Clover Patch",
  "Blue Hill",
  "Silverwood",
  "Fennel Field",
  "Ironwood Loft",
  "Willowbank",
  "Sage Harbor",
  "Redwood Stand",
  "Creek Nest",
  "Copper Birch",
  "Timberbrook",
  "Wildstone",
  "Oakbend",
  "Frosted Ridge",
  "Blue Mist",
  "Wildwood",
  "Clover Ridge",
  "Firelight Stand",
  "Stonebar",
  "Sunflower Field",
  "Oakwheel",
  "Crescent Hill",
  "Fern Point",
  "Misty Brook",
  "Saltwater Loft",
  "Wildfern",
  "Elmstone",
  "Pinebrook",
  "Redwood Loft",
  "Cedar Roost",
  "Drift Nest",
  "Ironstone",
  "Moonstone Point",
  "Timber Crest",
  "Bluecove",
  "Stonepatch",
  "Misty Nest",
  "Alder Hollow",
  "Ember Roost",
  "Wildcliff",
  "Rusty Nest",
  "Boulderbend",
  "Cloudberry",
  "Stoneforge",
  "Willowbrook",
  "Fernstone",
  "Goldenroot",
  "Starwheel",
  "Pinecroft",
  "Meadowbrook",
];

function App() {
  const openModal1 = () => setIsModal1Open(true);
  const closeModal1 = () => setIsModal1Open(false);
  const [isModal1Open, setIsModal1Open] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);

  const openModal2 = () => setIsModal2Open(true);
  const closeModal2 = () => setIsModal2Open(false);
  const generateName = () => {
    const name = companyNames[Math.floor(Math.random() * companyNames.length)];
    return `${name}`;
  };
  const generateNewName = useCallback(() => {
    setCompanyName(generateName());
    setProgress(0);
  }, []);
  const [companyName, setCompanyName] = useState("");
  const [progress, setProgress] = useState(0);
  const handleGenerateClick = () => {
    generateNewName();
  };
  const totalTime = 180000; // 3 minutes in milliseconds
  const updateInterval = 100; // Update every 100ms for smoother progress

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const increment = 100 / (totalTime / updateInterval);
        const newProgress = prevProgress + increment;
        if (newProgress >= 100) {
          clearInterval(interval); // Stop the interval when it reaches 100%
          return 100; // Ensure it doesn't go over 100%
        }
        return newProgress;
      });
    }, updateInterval);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [companyName, generateNewName]);

  
  



  return (
    <div className="container">
      <div className="firstRow">
        <div className='howItWorksbuttonContainer'><div>
              <button className='button' onClick={openModal1}>How it Works</button>
            </div>

            <Modal isOpen={isModal1Open} onClose={closeModal1}>
              <p style={{ textAlign: "left" }}>
                To be played between 3 or more players.
                <br />
                <br />
                Press the "Generate New Name" button to receive a ​random business
                name created by our AI. ​After receiving the business name, you
                or ​your team have 3 minutes to create and ​deliver a pitch to
                the other participating ​players or teams.
                <br />
                <br />
                The game helps players think on their ​feet, work
                collaboratively, and boost their ​confidence in public speaking.
                It's ​designed to build teamwork, enhance ​quick thinking, and
                overcome the fear of ​speaking in front of others.
                <br />
                <br />
                For best results play for 10,000 hours or ​more
              </p>
            </Modal>
</div>  
        </div>
      <div className="secondRow">
        <div className='logoContainer'><img className= "logo" src={gifImage} alt='react' /></div>
        <div className='descriptionContainer'><h3>My Startup Name:</h3></div>
        <div className='startupNameContainer'>{companyName && (
          <div>
            
            <div>
              <h1 className='highlight'>{companyName}</h1>
            </div>
          </div>
        )}</div>
      <div className='buttonContainer'><button className='generateButton' onClick={handleGenerateClick}>Generate New Name</button></div>
      </div>
      <div className="thirdRow"> 
      <div className='progressBarContainer'>
      <div className='progress-container'>
          <div
            className='progress-bar'
            style={{ height: `${progress}%` }}
          ></div>
        </div>
        
      </div>
      <div className='FAQbuttonContainer'><button className='button' onClick={openModal2}>FAQ</button></div>
      <Modal isOpen={isModal2Open} onClose={closeModal2}>
              <p style={{ textAlign: "left" }}>
                Here are some questions to get you started:
                <br />
                <br />
                What is the business idea? <br /> What problem does your
                business solve? <br /> Who is your target audience? <br />
                How does your business make money? <br />
                Where can this be applicable today?
              </p>
            </Modal>
      
      
      </div>
     
    </div>
  );
}

export default App;
